// https://github.com/selemondev/prime-store/blob/master/types/index.ts
import type { IOrderItemCart } from '~/types'
import { useSessionStorage } from '@vueuse/core'

/* Another way of writing store with return function */

export const useCartStore = defineStore('cart-store', () => {
  const cart = useSessionStorage<IOrderItemCart[]>('cart', [])
  const runtimeConfig = useRuntimeConfig()
  // const cartExpireTime = new Date().getTime() + 1 * 60 * 1000
  // Calculate cart item fee per item
  const cartFeeTotal = computed(() => {
    let processingFee = 0
    cart.value.forEach((item) => {
      //! Override processing fee
      if (item.processingOverrideEnabled) {
        if ((item as any)?.processingOverrideType === 'percentage') {
          processingFee +=
            (item.price * Number(item.processingOverrideFee)) / 100
        }

        if ((item as any)?.processingOverrideType === 'fixed') {
          processingFee += Number(item.processingOverrideFee)
        }
        //! Global percentage fee
      } else {
        if ((item.clientId as any)?.processingFeeType === 'percentage') {
          processingFee +=
            (item.price *
              (item.clientId as unknown as { processingFee: number })
                .processingFee) /
            100
        }

        if ((item.clientId as any)?.processingFeeType === 'fixed') {
          processingFee += (
            item.clientId as unknown as { processingFee: number }
          ).processingFee
        }
      }
    })
    // return processingFee;
    return parseFloat(Number(processingFee).toFixed(1))
  })

  // The total price of all the seats in the cart
  const cartTotalPrice = computed(() => {
    const cartItemsTotal = cart.value.reduce(
      (val: number, seat: { price: number; quantity: number }) =>
        val + seat.price * seat?.quantity,
      0
    )
    return parseFloat(Number(cartItemsTotal + cartFeeTotal.value).toFixed(1))
  })

  // Calculate tax total
  const cartTaxTotal = computed(() => {
    const taxFee = Number(runtimeConfig.public.taxFee)
    return parseFloat(((cartTotalPrice.value * taxFee) / 100).toFixed(1))
  })

  // This action will set onChartRendered current state of cart
  const setAlreadyInCart = (alreadyInCart: any) => {
    cart.value = [...alreadyInCart]
  }

  // Add normal seat to cart
  const selectSeatToCart = (
    seatId: string,
    price: number,
    type: string,
    label: string,
    own: string,
    parent: string,
    section: string,
    formattedPrice: string,
    eventId: any,
    poster: string,
    title: any,
    date: string,
    slug: any,
    clientId: any,
    processingFee: number,
    processingFeeType: string,
    publicWorkspaceKey: string,
    processingOverrideEnabled: boolean,
    processingOverrideType: string,
    processingOverrideFee: string,
    placeTitle: string,
    placeAddress: string,
    placeCity: string,
    sponsors: any,
    seatsioEventId: string,
    holdToken: string
  ) => {
    const cartItemId = generateCartHash()
    if (type === 'Seat') {
      // First time adding to cart
      if (cart.value.length == 0) {
        cart.value.push({
          cartItemId,
          seatId,
          price,
          type,
          label,
          own,
          parent,
          section,
          formattedPrice,
          eventId,
          poster,
          title,
          date,
          slug,
          clientId,
          quantity: 1,
          processingFee,
          processingFeeType,
          publicWorkspaceKey,
          processingOverrideEnabled,
          processingOverrideType,
          processingOverrideFee,
          placeTitle,
          placeAddress,
          placeCity,
          sponsors,
          seatsioEventId,
          holdToken,
        })
        // ,((cart as any).expiry = cartExpireTime)
      }

      // Cart already has items
      if (cart.value.length > 0) {
        // Check if the SEATS type is already in the cart
        let seatExist = cart.value.find(
          (item) => item.seatId === seatId && item.eventId === eventId
        )

        if (seatExist === undefined) {
          cart.value.push({
            cartItemId,
            seatId,
            price,
            type,
            label,
            own,
            parent,
            section,
            formattedPrice,
            eventId,
            poster,
            title,
            date,
            slug,
            clientId,
            quantity: 1,
            processingFee,
            processingFeeType,
            publicWorkspaceKey,
            processingOverrideEnabled,
            processingOverrideType,
            processingOverrideFee,
            placeTitle,
            placeAddress,
            placeCity,
            sponsors,
            seatsioEventId,
            holdToken,
          })
        }
      }
    }
  }

  // Add GA to cart
  const selectGAToCart = (
    seatId: string,
    numSelected: number,
    price: number,
    type: string,
    label: string,
    own: string,
    parent: string,
    section: string,
    formattedPrice: string,
    eventId: any,
    poster: string,
    title: any,
    date: string,
    slug: any,
    clientId: any,
    processingFee: number,
    processingFeeType: string,
    publicWorkspaceKey: string,
    processingOverrideEnabled: boolean,
    processingOverrideType: string,
    processingOverrideFee: string,
    placeTitle: string,
    placeAddress: string,
    placeCity: string,
    sponsors: any,
    seatsioEventId: string,
    holdToken: string
  ) => {
    // Always remove all GAs that belongs to event
    // cart.value = cart.value.filter(obj => obj.eventId !== eventId);

    // Start adding GAs from the numSelected parameter. This parameter is always accurate
    // for (let i = 0; i < numSelected; i++) {
    const cartItemId = generateCartHash()
    cart.value.push({
      cartItemId,
      seatId,
      price,
      type,
      label,
      own,
      parent,
      section,
      formattedPrice,
      eventId,
      poster,
      title,
      date,
      slug,
      clientId,
      quantity: 1,
      processingFee,
      processingFeeType,
      publicWorkspaceKey,
      processingOverrideEnabled,
      processingOverrideType,
      processingOverrideFee,
      placeTitle,
      placeAddress,
      placeCity,
      sponsors,
      seatsioEventId,
      holdToken,
    })
    // ,((cart as any).expiry = cartExpireTime)

    // }
  }

  // Remove seats from cart
  const deselectSeatFromCart = async (
    seatId: string,
    eventId: any,
    holdToken: any
  ) => {
    // get index of object we want to remove
    const removeIndex = cart.value.findIndex(
      (item) => item.eventId === eventId && item.seatId === seatId
    )
    // remove object
    cart.value.splice(removeIndex, 1)
  }

  // Remove GA from cart
  const deselectGAFromCart = async (
    seatId: string,
    numSelected: number,
    price: number,
    type: string,
    label: string,
    own: string,
    parent: string,
    section: string,
    formattedPrice: string,
    eventId: any,
    poster: string,
    title: any,
    date: string,
    slug: any,
    clientId: any,
    processingFee: number,
    processingFeeType: string,
    publicWorkspaceKey: string,
    processingOverrideEnabled: boolean,
    processingOverrideType: string,
    processingOverrideFee: string,
    placeTitle: string,
    placeAddress: string,
    placeCity: string,
    sponsors: any,
    seatsioEventId: string,
    holdToken: string
  ) => {
    // Always remove all GAs that belongs to event
    // cart.value = cart.value.filter(obj => obj.seatId !== seatId);

    const index = cart.value.findIndex(
      (item) => item.seatId === seatId && item.eventId === eventId
    )
    if (index !== -1) {
      cart.value.splice(index, 1)
    }

    // Start adding GAs from the numSelected parameter. This parameter is always accurate
    //for (let i = 0; i < numSelected; i++) {
    //    const cartItemId = generateCartHash();
    ///    cart.value.push({ cartItemId, seatId, price, type, label, own, parent, section, formattedPrice, eventId, poster, title, date, slug, clientId, quantity: 1, processingFee, processingFeeType,  publicWorkspaceKey, holdToken });
    // }
  }

  // Release item from cart
  const releaseItemRemoveFromCart = async (
    cartItemId: string,
    eventId: string,
    seatId: string,
    holdToken: string
  ) => {
    // We added ga-holdtoken to all the GeneralAdmissionAreas
    // GeneralAdmissionAreas is no longer related to seatsio
    if (holdToken === 'ga-holdtoken') {
      const index = cart.value.findIndex((item) => item.seatId === seatId)
      if (index !== -1) {
        cart.value.splice(index, 1)
        return {
          success: true,
        }
      }
    } else {
      const useSeatsio = useSeatsioStore()
      // This will also trigger object deselect which will trigger cart remove. No need to do the cart remove here
      const release = await useSeatsio.releaseSeatsFromSeatsio(
        eventId,
        seatId,
        holdToken
      )
      if (release && release.success) {
        return {
          success: true,
        }
      }
    }
  }

  // Empty cart
  const emptyCart = () => {
    cart.value = []
  }

  // Generate a random cart hash
  const generateCartHash = () => {
    return Math.random().toString(20).substr(2, 200)
  }

  return {
    cart,
    cartFeeTotal,
    cartTotalPrice,
    cartTaxTotal,
    emptyCart,
    selectSeatToCart,
    selectGAToCart,
    deselectSeatFromCart,
    deselectGAFromCart,
    releaseItemRemoveFromCart,
    generateCartHash,
    setAlreadyInCart,
  }
})
